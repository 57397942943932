const appConfig = {
    apiPrefix:
        process.env.NODE_ENV === 'production'
            ? 'https://xproject.soexpressnetwork.info/'
            : 'http://localhost:3005/',
    apiVersion: 'v1',
    authenticatedEntryPath: '/',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
}

export default appConfig
