import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
    apiGetActiveOrder,
    apiGetAllOrders,
    apiGetOrderDetail,
    apiGetOrderStatus,
} from 'services/OrderService'

export const SLICE_NAME = 'orders'

export const getActiveOrder = createAsyncThunk('orders/getOrder', async () => {
    const response = await apiGetActiveOrder()
    console.log(response)
    return response.data
})

export const getOrderStatus = createAsyncThunk(
    'orders/getOrderStatus',
    async (data) => {
        const response = await apiGetOrderStatus(data)
        console.log(response)
        return response.data
    }
)

export const getOrderDetail = createAsyncThunk(
    'orders/getOrderDetail',
    async (data) => {
        const response = await apiGetOrderDetail(data)
        console.log(response)
        return response.data
    }
)

export const getOrderList = createAsyncThunk(
    'orders/getOrderList',
    async ({ page, limit, sort, status }) => {
        // Call your API function passing the parameters
        const response = await apiGetAllOrders({ page, limit, sort, status })
        return response.data
    }
)
const initialState = {
    loading: true,
    active: {},
    list: {
        loading: true,
        tableData: {
            total: 0,
            page: 1,
            limit: 5,
            sort: {
                order: '',
                key: '',
            },
        },
        filterData: {
            status: '',
        },
        items: [],
    },
    orderDetail: {
        loading: true,
        item: {},
    },
    orderStatus: {
        loading: true,
        item: {},
    },
}

const orderSlice = createSlice({
    name: `${SLICE_NAME}/state`,
    initialState,
    reducers: {
        //
        // setProductList: (state, action) => {
        //     state.productList = action.payload
        // },
        // setCurrentProduct: (state, action) => {
        //     state.selectedProduct = action.payload
        // },
        setOrderListTableData: (state, action) => {
            state.list.tableData = action.payload
        },
        setOrderListTableFilter: (state, action) => {
            state.list.filterData = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getActiveOrder.fulfilled, (state, action) => {
                console.log(action)
                state.active = action.payload
                // state.tableData.total = action.payload.totalResults
                state.loading = false
            })
            .addCase(getActiveOrder.pending, (state) => {
                state.loading = true
            })
            .addCase(getActiveOrder.rejected, (state, action) => {
                state.loading = false
                state.active = {}
            })
            .addCase(getOrderStatus.fulfilled, (state, action) => {
                state.orderStatus.item = action.payload
                state.orderStatus.loading = false
            })
            .addCase(getOrderStatus.pending, (state) => {
                state.orderStatus.loading = true
            })
            .addCase(getOrderStatus.rejected, (state, action) => {
                state.orderStatus.loading = false
                state.orderStatus.item = {}
            })
            .addCase(getOrderDetail.fulfilled, (state, action) => {
                state.orderDetail.item = action.payload
                state.orderDetail.loading = false
            })
            .addCase(getOrderDetail.pending, (state) => {
                state.orderDetail.loading = true
            })
            .addCase(getOrderDetail.rejected, (state, action) => {
                state.orderDetail.loading = false
                state.orderDetail.item = {}
            })
            .addCase(getOrderList.fulfilled, (state, action) => {
                state.list.items = action.payload.results
                state.list.tableData.total = action.payload.totalResults
                state.list.loading = false
                // state.tableData.total = action.payload.totalResults
            })
            .addCase(getOrderList.pending, (state) => {
                state.list.loading = true
            })
            .addCase(getOrderList.rejected, (state, action) => {
                state.loading = false
                state.list.items = []
            })
    },
})

export const { setOrderListTableData, setOrderListTableFilter } =
    orderSlice.actions

export default orderSlice.reducer
