import ApiService from './ApiService'

export async function apiCreateOrder(data) {
    return ApiService.fetchData({
        url: '/orders',
        method: 'post',
        data,
    })
}
export function apiCreateOrderWithWallet(data) {
    return ApiService.fetchData({
        url: '/orders/create-order',
        method: 'post',
        data,
    })
}

export function apiGetActiveOrder() {
    return ApiService.fetchData({
        url: '/orders/active',
        method: 'get',
    })
}

export function apiGetAllOrders(data) {
    return ApiService.fetchData({
        url: '/orders/customer/list',
        method: 'get',
        params: {
            limit: data.limit,
            page: data.page,
            status: data.status,
            sortBy: `${data.sort?.key}:${data.sort?.order}`,
        },
    })
}

export function apiGetOrderDetail(data) {
    console.log(data)
    return ApiService.fetchData({
        url: `/orders/customer/detail/${data}`,
        method: 'get',
    })
}

export function apiSubmitOrder(data) {
    return ApiService.fetchData({
        url: '/orders/customer/submit',
        method: 'post',
        data,
    })
}

export function apiGetOrderStatus(orderID) {
    return ApiService.fetchData({
        url: `/orders/customer/submitted/${orderID}`,
        method: 'get',
    })
}
