import ApiService from './ApiService'

export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: '/auth/customer/login',
        method: 'post',
        data,
    })
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: '/auth/customer/register',
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/sign-out',
        method: 'post',
        data,
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data,
    })
}

export async function apiChangePassword(data) {
    return ApiService.fetchData({
        url: '/auth/customer/change-password',
        method: 'post',
        data,
    })
}
export async function apiGetCustomerSessions() {
    return ApiService.fetchData({
        url: '/auth/customer/sessions',
        method: 'get',
    })
}
export async function apiGetCustomerInfo() {
    return ApiService.fetchData({
        url: '/auth/customer/info',
        method: 'get',
    })
}

export async function apiUpdateCustomerInfo(data) {
    return ApiService.fetchData({
        url: '/customers/updateInfoByCustomer',
        method: 'post',
        data,
    })
}

export async function apiSendVerificaitonEmail(data) {
    return ApiService.fetchData({
        url: '/auth/customer/verification/email/send',
        method: 'post',
        data,
    })
}

export async function apiVerifyEmailToken(params) {
    return ApiService.fetchData({
        url: '/auth/customer/verification/email/verify',
        method: 'get',
        params,
    })
}

export async function apiSendVerificaitionSMS(data) {
    return ApiService.fetchData({
        url: '/auth/customer/verification/sms/send',
        method: 'post',
        data,
    })
}

export async function apiVerifySMSCode(data) {
    return ApiService.fetchData({
        url: '/auth/customer/verification/sms/verify',
        method: 'get',
        data,
    })
}
